import { useUnit } from 'effector-react';

import { TaskEditModalButton } from '@/features/task-edit';

import { getTaskTimelineString, Task, taskToggled } from '@/entities/tasks';

import { Badge } from '@/shared/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/shared/components/ui/card';
import { Checkbox } from '@/shared/components/ui/checkbox';
import { When } from '@/shared/components/when';
import { cn } from '@/shared/lib/cn';

export interface TaskItemProps {
  task: Task;
}

export const TaskItem = (props: TaskItemProps) => {
  const { task } = props;

  const { toggle } = useUnit({
    toggle: taskToggled,
  });

  return (
    <Card
      key={task.id}
      className={cn(
        'w-full',
        // 100% of screen - 4rem to see edge of the card
        // 'w-[calc(1*(100%-4rem))]',
        // 1/3 of screen - 4rem to see edge of the card
        // 'lg:w-[calc(1/3*(100%-4rem))]',
        'shrink-0 border-2',
        task.completed && 'border-green-300/15 bg-green-500/10',
      )}
    >
      <CardHeader>
        <div className="flex items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            <Checkbox onCheckedChange={() => toggle(task)} checked={task.completed} />

            <CardTitle className="inline">{task.title}</CardTitle>
          </div>

          <TaskEditModalButton task={task} />
        </div>
      </CardHeader>

      <CardContent className="flex flex-1 flex-col space-y-4">
        <When condition={task.description}>
          <div>
            <p className="text-sm text-muted-foreground">{task.description}</p>
          </div>
        </When>

        <div className="flex items-center justify-between">
          <span className="text-xs text-muted-foreground">{getTaskTimelineString(task)}</span>

          {/* <span className="text-xs text-muted-foreground">Every day</span> */}

          <div className="flex justify-end">
            <Badge
              className={cn('border-2 border-green-800 text-green-600', task.completed && 'bg-green-800 text-white')}
              variant="outline"
            >
              EXP +{task.exp}
            </Badge>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
