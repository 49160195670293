import { fork, serialize } from 'effector';

import { TodayPage } from '@/page-content/today';

import { EFFECTOR_STATE_KEY } from '@/shared/lib/effector';

export const getServerSideProps = () => {
  const scope = fork();

  return {
    props: {
      [EFFECTOR_STATE_KEY]: serialize(scope),
    },
  };
};

export default TodayPage;
