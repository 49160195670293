import { useUnit } from 'effector-react';

import { AppLayout } from '@/widgets/app-layout';
import { Heatmap } from '@/widgets/heatmap';
import { Userbar } from '@/widgets/userbar';

import { $todaysActivity, $todayTasksAmount, $todayTasksCompleted } from '@/entities/tasks';

import { EnhancedNextPage } from '@/shared/lib/next/enhanced-next-page';

import { TaskItem } from './ui/task-item';

export const TodayPage: EnhancedNextPage = () => {
  const { todaysActivity, todayTasksAmount, todayTasksCompleted } = useUnit({
    todaysActivity: $todaysActivity,
    todayTasksAmount: $todayTasksAmount,
    todayTasksCompleted: $todayTasksCompleted,
  });

  return (
    <div>
      <div className="container space-y-6">
        <div className="space-y-6">
          <h1 className="text-2xl font-semibold">Welcome back!</h1>
          <span className="text-sm text-muted-foreground">
            {new Date().toLocaleDateString('default', { weekday: 'long', month: 'long', day: '2-digit' })}
          </span>
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-5">
            <Userbar />
          </div>

          <div className="col-span-12 lg:col-span-7">
            <Heatmap />
          </div>
        </div>

        <div className="space-y-6">
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-semibold">Today tasks</h2>

            <span className="text-lg font-semibold">
              {todayTasksCompleted}/{todayTasksAmount}
            </span>
          </div>

          <div className="w-full overflow-hidden">
            <div className="flex flex-col items-start gap-4 overflow-scroll">
              {todaysActivity.tasks.map((task) => (
                <TaskItem key={task.id} task={task} />
              ))}

              {/* <TaskEditModalButton>
                <Button
                  variant="outline"
                  className="flex h-auto w-12 shrink-0 items-center justify-center self-stretch rounded-xl border-2 bg-card p-0 text-card-foreground shadow"
                >
                  <RiAddLine stroke="currentColor" fill="currentColor" className="h-6 w-6 text-muted-foreground" />
                </Button>
              </TaskEditModalButton> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TodayPage.Layout = AppLayout;
