import React from 'react';
import { isSameDay } from 'date-fns';
import { useUnit } from 'effector-react';
import { countBy, range } from 'es-toolkit';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';

import { $activity, $tasksCompletedForYear } from '@/entities/tasks';

import { Button } from '@/shared/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/shared/components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/components/ui/tooltip';
import { cn } from '@/shared/lib/cn';

import { getDaysInMonth } from './lib';
import { $currentDate, $currentQuarter, $startingMonthByQuarter, goNextQuarter, goPrevQuarter } from './model';

export const Heatmap = () => {
  const {
    activities,
    tasksCompletedForCurrentYear,
    nextQuarter,
    prevQuarter,
    currentQuart,
    currentDate,
    startingMonth,
  } = useUnit({
    activities: $activity,
    tasksCompletedForCurrentYear: $tasksCompletedForYear,
    nextQuarter: goNextQuarter,
    prevQuarter: goPrevQuarter,
    currentQuart: $currentQuarter,
    currentDate: $currentDate,
    startingMonth: $startingMonthByQuarter,
  });

  const quatersMonths = range(startingMonth, startingMonth + 3);

  return (
    <Card className="border-2">
      <CardHeader className="flex-row items-center justify-between space-y-0">
        <CardTitle>Heatmap</CardTitle>

        <div className="flex items-center space-x-2">
          <Button onClick={prevQuarter} variant="ghost" size="icon-xs">
            <RiArrowLeftLine />
          </Button>

          <span className="text-sm text-muted-foreground">
            Q{currentQuart} {currentDate.getFullYear()}
          </span>

          <Button onClick={nextQuarter} variant="ghost" size="icon-xs">
            <RiArrowRightLine />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <TooltipProvider delayDuration={400} skipDelayDuration={200} disableHoverableContent={true}>
          <div className="space-y-4">
            {quatersMonths.map((month) => {
              const daysInMonth = getDaysInMonth(month, new Date().getFullYear());

              return (
                <div key={month} className="flex shrink-0 items-center space-x-8">
                  <h6 className="w-8 shrink-0 whitespace-nowrap text-sm font-semibold text-muted-foreground">
                    {new Date(currentDate.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })}
                  </h6>

                  <div className="flex flex-wrap gap-1">
                    {range(1, daysInMonth).map((day) => {
                      const dayDate = new Date(currentDate.getFullYear(), month, day);

                      const dayActivity = activities.find((activity) => isSameDay(activity.date, dayDate));
                      const { completed = 0, uncompleted = 0 } = countBy(dayActivity?.tasks ?? [], (item) => {
                        return item.completed ? 'completed' : 'uncompleted';
                      });

                      const activityScore = (completed / (completed + uncompleted)) * 100;

                      return (
                        <Tooltip key={day}>
                          <TooltipTrigger>
                            <div
                              className={cn('h-3 w-3 rounded-[2px] bg-neutral-300 dark:bg-neutral-800', {
                                'bg-green-900 dark:bg-green-900': activityScore > 0,
                                'bg-green-800 dark:bg-green-800': activityScore >= 40,
                                'bg-green-600 dark:bg-green-700': activityScore >= 80,
                                'bg-green-400 dark:bg-green-600': activityScore === 100,
                              })}
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            <span>
                              Completed {completed} tasks out of {completed + uncompleted} on{' '}
                              {dayDate.toLocaleDateString('default', { day: '2-digit', month: 'short' })}
                            </span>
                          </TooltipContent>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </TooltipProvider>
      </CardContent>

      <CardFooter>
        <div className="flex w-full items-start justify-between">
          <div className="flex gap-2">
            <span className="text-sm text-muted-foreground">Completed</span>
            <span className="text-sm font-semibold">{tasksCompletedForCurrentYear}</span>
          </div>

          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">Less</span>

            <div className="flex gap-1">
              <div className={cn('h-3 w-3 rounded-[2px] bg-neutral-300 dark:bg-neutral-800')} />
              <div className={cn('h-3 w-3 rounded-[2px] bg-green-900')} />
              <div className={cn('h-3 w-3 rounded-[2px] bg-green-800')} />
              <div className={cn('h-3 w-3 rounded-[2px] bg-green-700')} />
              <div className={cn('h-3 w-3 rounded-[2px] bg-green-600')} />
            </div>

            <span className="text-sm text-muted-foreground">More</span>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
