import { getQuarter } from 'date-fns';
import { combine, createEvent, createStore, sample } from 'effector';

import { getStartingMonthIndexForQuarter } from './lib';

export const $currentQuarter = createStore(getQuarter(new Date()));
export const $currentDate = createStore(new Date());

export const $startingMonthByQuarter = combine($currentQuarter, (quarter) => getStartingMonthIndexForQuarter(quarter));

export const goNextQuarter = createEvent();
export const goPrevQuarter = createEvent();

export const resetQuarterYear = createEvent<Date>();

sample({
  clock: goNextQuarter,
  source: { quarter: $currentQuarter, date: $currentDate },
  filter: ({ quarter }) => quarter + 1 > 4,
  fn: ({ date }) => {
    const currentYear = date.getFullYear();
    const newYear = currentYear + 1;

    const newDate = new Date(newYear, 0, 1);

    return newDate;
  },
  target: $currentDate,
});

sample({
  clock: goNextQuarter,
  source: $currentQuarter,
  fn: (quarter) => {
    const nextQuarter = quarter + 1;

    if (nextQuarter > 4) {
      return 1;
    }

    return nextQuarter;
  },
  target: $currentQuarter,
});

/////////

sample({
  clock: goPrevQuarter,
  source: { quarter: $currentQuarter, date: $currentDate },
  filter: ({ quarter }) => quarter - 1 === 0,
  fn: ({ date }) => {
    const currentYear = date.getFullYear();
    const prevYear = currentYear - 1;

    const newDate = new Date(prevYear, 0, 1);

    return newDate;
  },
  target: $currentDate,
});

sample({
  clock: goPrevQuarter,
  source: $currentQuarter,
  fn: (quarter) => {
    const prevQuarter = quarter - 1;

    if (prevQuarter === 0) {
      return 4;
    }

    return prevQuarter;
  },
  target: $currentQuarter,
});
