import { useUnit } from 'effector-react';

import { $levelUpProgress, $totalExpForLevelUp, $userExp, $userLevel } from '@/entities/users';

import { Avatar, AvatarFallback, AvatarImage } from '@/shared/components/ui/avatar';
import { Card, CardContent, CardHeader } from '@/shared/components/ui/card';
import { Progress } from '@/shared/components/ui/progress';

export const Userbar = () => {
  const { currentLevel, currentExp, totalExp, levelProgress } = useUnit({
    currentLevel: $userLevel,
    currentExp: $userExp,
    totalExp: $totalExpForLevelUp,
    levelProgress: $levelUpProgress,
  });

  return (
    <Card className="border-2">
      <CardHeader>
        <div className="flex items-center space-x-4">
          <Avatar>
            <AvatarImage src={undefined} />
            <AvatarFallback>AN</AvatarFallback>
          </Avatar>

          <div className="flex flex-col">
            <span className="text-lg font-semibold">Anon</span>
            <span className="text-sm text-muted-foreground">Level {currentLevel}</span>
          </div>
        </div>
      </CardHeader>

      <CardContent>
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold">EXP</span>

            <div>
              <span className="text-lg font-semibold">{currentExp}</span>
              <span className="text-sm text-muted-foreground">/</span>
              <span className="text-sm text-muted-foreground">{totalExp}</span>
            </div>
          </div>

          <Progress value={levelProgress} className="w-full" />
        </div>
      </CardContent>
    </Card>
  );
};
