export function getDaysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

/**
 * Get starting month index for given quarter
 * @param quarter
 * @returns starting month index
 * @example getStartingMonthIndexForQuarter(2) // 3 - start of Q2 is April
 */
export const getStartingMonthIndexForQuarter = (quarter: number) => {
  return (quarter - 1) * 3;
};
